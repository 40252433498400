import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '../../components/ui/Button';
import styled from 'styled-components';
import Router from './routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { yellow, grey } from '@material-ui/core/colors';
import ContactUs from './contact-us';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { hideContactUs, showContactUs } from '../../redux/actions/ui';

const SiteWrapper = styled.div`
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1c2127;
`;
const Home = () => {
    return (
        <SiteWrapper>
            <Header />
            <Router />
            {/* <Footer /> */}
        </SiteWrapper>
    );
};

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-top: 29px;
    padding-left: 39px;
    align-items: center;
    @media (max-width: 810px) {
        flex-direction: column;
        padding: 5px;
        padding-top: 70px;
    }
`;

export const Header: React.FC = () => {
    const useHorizontalBar = !useMediaQuery('(min-width:810px)');
    const isContactUsPage = useRouteMatch('/contact-us');
    return (
        <HeaderWrapper>
            <Logo size="large" />
            {useHorizontalBar ? <HorizontalBar /> : <VerticalBar />}
            <SubTitle>FRIENDLY SOFTWARE</SubTitle>
            {!isContactUsPage && <ContactUsButton />}
        </HeaderWrapper>
    );
};

const SubTitle = styled.span`
    color: white;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 27px;
`;

const VerticalBar = styled.div`
    width: 1px;
    height: 40px;
    border-left: 1px solid white;
    margin-left: 25px;
    margin-right: 20px;
`;

const HorizontalBar = styled.div`
    width: 50%;
    height: 1px;
    border-bottom: 1px solid white;
    margin: 25px 0px;
`;

const LogoTypography = styled.span`
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: white;
    letter-spacing: 7.8px;
    cursor: pointer;
`;
export const Logo = ({ size }: { size: 'large' | 'medium' | 'small' }) => {
    const history = useHistory();
    return (
        <LogoTypography onClick={() => history.push('/')}>
            OLLIEWARE
        </LogoTypography>
    );
};

const ContactButton = styled.button`
    background-color: #da475d;
    width: 157px;
    height: 32px;
    border-radius: 5px;
    color: white;
    border: none;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.2px;
    justify-self: flex-end;
`;
const Wrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 55px;
    @media (max-width: 810px) {
        flex-direction: column;
        margin: 25px;
    }
`;
export const ContactUsButton = () => {
    const history = useHistory();
    return (
        <Wrapper>
            <ContactButton onClick={() => history.push('/contact-us')}>
                CONTACT US
            </ContactButton>
        </Wrapper>
    );
};

export const Footer = () => {
    const theme = useTheme();
    const FooterContainer = styled.div`
        height: 100;
        width: 100 %;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: ${theme.palette.grey['200']};
    `;
    return (
        <FooterContainer>
            <Typography color="textPrimary">Made with ❤️ in Atlanta</Typography>
        </FooterContainer>
    );
};
export default Home;
