import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PricingSVG } from '../../../static/icons/pricing.svg';
import { ReactComponent as RapidSVG } from '../../../static/icons/rapid.svg';
import { ReactComponent as TeamworkSVG } from '../../../static/icons/teamwork.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 100px;
`;
const TitleRegular = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 3em;
    line-height: 1em;
    letter-spacing: -0.2px;
    color: #da475d;
    align-items: center;
    justify-content: center;
`;

const TitleMain = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 3.5em;
    line-height: 1em;
    letter-spacing: -0.2px;
    color: #da475d;
    align-self: center;
`;

const OfferingsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;
`;

const Offering = styled.div`
    display: flex;
    flex-direction: column;
    width: 12em;
    justify-content: center;
    align-items: center;
    @media (max-width: 810px) {
        margin: 30px 0px;
    }
`;

const OfferingText = styled.span`
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.2px;
    margin-top: 25px;
`;

const SVGWrapper = styled.div`
    width: 3em;
    height: 3em;
`;

const Welcome = () => (
    <Wrapper>
        <CTAContainer>
            <TitleRegular>DELIVERING</TitleRegular>
            <TitleMain>QUALITY</TitleMain>
            <TitleRegular>IN DAYS NOT MONTHS</TitleRegular>
        </CTAContainer>
        <OfferingsContainer>
            <Offering>
                <SVGWrapper>
                    <RapidSVG height="100%" width="100%" />
                </SVGWrapper>
                <OfferingText>RAPID PROTOTYPING</OfferingText>
            </Offering>
            <Offering style={{ margin: '0px 77px' }}>
                <SVGWrapper>
                    <TeamworkSVG height="100%" width="100%" />
                </SVGWrapper>
                <OfferingText>TEAMWORK CENTERED</OfferingText>
            </Offering>
            <Offering>
                <SVGWrapper>
                    <PricingSVG height="100%" width="100%" />
                </SVGWrapper>
                <OfferingText>SIMPLE PRICING PLANS</OfferingText>
            </Offering>
        </OfferingsContainer>
    </Wrapper>
);

export default Welcome;
