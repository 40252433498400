import React from 'react';
import { useForm } from '../../../components/form';
import Button from '../../../components/ui/Button';
import TextField from '../../../components/ui/Input';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { displaySnackbarMessage } from '../../../redux/actions/snackbar';
import { usePostAPI } from '../../../api';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    //align-items: center;
    justify-content: center;
    width: 75%;
`;

const FormWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 810px) {
        margin-bottom: 70px;
    }
`;

const ContactUsForm: React.FC = () => {
    const dispatch = useDispatch();

    const form = useForm({
        defaultValues: {
            name: '',
            email: '',
            message: '',
        },
        onSuccess: () => {
            dispatch(
                displaySnackbarMessage(
                    'success',
                    `Thanks for reaching out ${form.values.name}, we'll be in touch soon!`,
                ),
            );
            form.resetForm();
        },
    });

    const sendEmail = usePostAPI('contact-us-ow');

    const onSubmit = () =>
        sendEmail({
            name: form.values.name,
            email: form.values.email,
            message: form.values.message,
        });

    return (
        <FormWrapper>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <TextField
                    name="name"
                    placeholder="NAME"
                    onChange={form.handleChange}
                    value={form.values.name}
                />
                <TextField
                    name="email"
                    placeholder="EMAIL"
                    onChange={form.handleChange}
                    value={form.values.email}
                />
                <TextField
                    name="message"
                    multiline
                    placeholder="MESSAGE"
                    style={{ height: 130 }}
                    onChange={form.handleChange}
                    value={form.values.message}
                />
                <Button
                    type="submit"
                    disabled={
                        form.submitting ||
                        !(
                            form.values.name &&
                            form.values.email &&
                            form.values.message
                        )
                    }
                >
                    Send
                </Button>
            </Form>
        </FormWrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    align-items: center;
`;

const Title = styled.span`
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    font-size: 3.5em;
    line-height: 1em;
    letter-spacing: -0.2px;
    width: 80%;
    color: #53b9e5;
    justify-self: center;
    margin-top: 70px;
    @media (max-width: 810px) {
        width: 100%;
        text-align: center;
        margin-bottom: 70px;
    }
    @media (max-width: 900px) {
        margin-bottom: 70px;
    }
`;

const ContactUsBody = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
    @media (max-width: 810px) {
        flex-direction: column;
    }
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const DetailBlock = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-bottom: 50px;
`;

const DetailHeader = styled.div`
    font-size: 1.25em;
    color: #53b9e5;
    font-weight: 600;
`;

const DetailBody = styled.span`
    font-size: 1em;
    color: #53b9e5;
`;

const ContactUs = () => {
    return (
        <Wrapper>
            <Title>REACH OUT</Title>
            <ContactUsBody>
                <Details>
                    <DetailBlock>
                        <DetailHeader>FROM THE GROUND UP.</DetailHeader>
                        <DetailBody>
                            Our expertise ranges from complex cloud
                            infrastructure to diverse user experiences that look
                            beautiful on any device.
                        </DetailBody>
                    </DetailBlock>
                    <DetailBlock>
                        <DetailHeader>
                            PROFESSIONALISM AT OUR CORE.
                        </DetailHeader>
                        <DetailBody>
                            With SCRUM based management, we ensure the best
                            possible product at all stages of delivery.
                        </DetailBody>
                    </DetailBlock>
                    <DetailBlock>
                        <DetailHeader>SIMPLE CONTRACTS.</DetailHeader>
                        <DetailBody>
                            We believe that our work speaks for itself. No
                            nonsense contracts focus on delivering quickly,
                            starting at 10k per week.
                        </DetailBody>
                    </DetailBlock>
                </Details>
                <ContactUsForm />
            </ContactUsBody>
        </Wrapper>
    );
};

export default ContactUs;
