import React from 'react';
import { ButtonProps } from '@material-ui/core';
import Link from '../Link';
import styled from 'styled-components';

interface IButton extends ButtonProps {
    to?: string;
}

const StyledButton = styled.button`
    background: #53b9e5;
    color: white;
    font-size: 0.75em;
    border-radius: 5px;
    height: 2.5em;
    width: 100%;
    cursor: pointer;
    border: 1px solid white;
    :disabled {
        background: #dddddd;
        cursor: auto;
    }
`;

const Button = (props: IButton) =>
    props.to ? (
        <Link to={props.to} hideUnderline>
            <StyledButton {...props}>{props.children}</StyledButton>
        </Link>
    ) : (
        <StyledButton {...props}>{props.children}</StyledButton>
    );

Button.defaultProps = {
    variant: 'contained',
    color: 'primary',
    style: { color: 'white' },
};

export default Button;
