import React from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
    background: #ffffff;
    border: 2px solid grey;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.75em;
    height: 2.5em;
    color: #53b9e5;
    margin-bottom: 25px;
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #53b9e5;
        opacity: 1; /* Firefox */
    }
    :focus {
        border: 2px solid #53b9e5;
        outline: none;
    }
    :hover {
        border: 2px solid dark-grey;
    }
`;
const Input = styled.input`
    background: #ffffff;
    border: 2px solid grey;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.75em;
    height: 2.5em;
    color: #53b9e5;
    margin-bottom: 25px;
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #53b9e5;
        opacity: 1; /* Firefox */
    }
    :focus {
        border: 2px solid #53b9e5;
        outline: none;
    }
    :hover {
        border: 2px solid dark-grey;
    }
`;

const TextField: React.FC<any> = ({
    onChange,
    name,
    placeholder,
    value,
    style,
    multiline,
}) => {
    let InputElement: any = multiline ? TextArea : Input;
    return (
        <InputElement
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={{ ...(style ? style : {}) }}
            name={name}
        />
    );
};

export default TextField;
