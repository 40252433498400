import React from 'react';
import { BrowserRouter as RRouter, Switch } from 'react-router-dom';
import NonAuthenticatedRoute from './NonAuthenticatedRoute';
import Home from '../../screens/home';

const Router: React.FC = () => (
    <RRouter>
        <Switch>
            <NonAuthenticatedRoute component={Home} path="/" />
        </Switch>
    </RRouter>
);

export default Router;
