import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Router from './modules/components/router';
import Snackbar from './modules/components/snackbar';
import withProvider from './modules/redux/withProvider';
import { useMediaQuery } from '@material-ui/core';

function App() {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#DA475D',
            },
            secondary: {
                main: '#53B9E5',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router />
            <Snackbar />
        </ThemeProvider>
    );
}

export default withProvider(App);
