import React from 'react';
import { Switch } from 'react-router-dom';
import NonAuthenticatedRoute from '../../components/router/NonAuthenticatedRoute';
import Welcome from './welcome';
import ContactUs from './contact-us';
import PrivacyPolicy from './privacy-policy';

const Router: React.FC = () => (
    <Switch>
        <NonAuthenticatedRoute component={Welcome} exact path="/" />
        <NonAuthenticatedRoute component={ContactUs} path="/contact-us" />
        <NonAuthenticatedRoute component={PrivacyPolicy} path="/privacy-policy" />
    </Switch>
);

export default Router;
