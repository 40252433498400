import { SHOW_CONTACT_US, HIDE_CONTACT_US } from '../../actions/ui';
import { ReduxAction } from '../../interfaces';

export interface UIState {
    contactUsVisible: boolean;
}

const defaultState = {
    contactUsVisible: false,
};

export default (state = defaultState, action: ReduxAction) => {
    switch (action.type) {
        case SHOW_CONTACT_US:
            return {
                ...state,
                contactUsVisible: true,
            };
        case HIDE_CONTACT_US:
            return {
                ...state,
                contactUsVisible: false,
            };
        default:
            return state;
    }
};
